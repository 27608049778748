import React, { useReducer } from "react"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import axios from "axios"
import widont from "widont"

import styles from "./password.module.scss"
import InputText from "./inputText"
import submitHubSpotForm from "../services/submitHubSpotForm"

const authFunction = "/.netlify/functions/auth"
const formId = "aaf93b4b-95e9-4da0-84d6-5c69af7e5855"

export default props => {
  const slug = props.slug
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "input":
          return {
            ...state,
            fields: {
              ...state.fields,
              [action.fieldName]: action.value,
            },
          }
        case "submitted":
          return {
            ...state,
            submitting: true,
            success: false,
            error: false,
          }
        case "succeeded":
          return {
            ...state,
            submitting: false,
            success: true,
            error: false,
            data: action.data,
          }
        case "failed":
          return {
            ...state,
            submitting: false,
            error: true,
            success: false,
          }
        default:
          return state
      }
    },
    {
      fields: {
        password: "",
        email: "",
      },
      submitting: false,
      success: false,
      error: false,
      data: null,
    }
  )

  const handleSubmit = event => {
    event.preventDefault()
    dispatch({ type: "submitted" })

    axios
      .post(authFunction, {
        password: state.fields.password,
        slug: slug,
      })
      .then(data => {
        // Track custom Google Analytics events
        trackCustomEvent({
          category: "Secret Pages",
          action: "Password Success",
          label: `Page ID: ${data.data.secretPage.id}`,
        })
        submitHubSpotForm(formId, [
          {
            name: "email",
            value: state.fields.email,
          },
        ])
          .then(data => {
            // console.log(data)
          })
          .catch(error => {
            console.log(error)
          })
        dispatch({ type: "succeeded", data })
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: "failed" })
      })
  }

  if (state.success) {
    return props.children(state.data)
  }
  // JSX
  return (
    <div className="page-content-centered">
      <div className="wrapper">
        <div className="mb-2 text-centerr">
          <div className="columns is-mobile is-centered is-multiline">
            <div className="column is-8-tablet is-6-desktop is-5-widescreen">
              <h1 className="h-2 text-centerr">This page is private.</h1>
            </div>
          </div>
          <div className="columns is-mobile is-centered is-multiline">
            <div className="column is-7-tablet is-6-desktop is-5-widescreen">
              <p className="mb-2">
                {widont(
                  `Please enter an access code and your email address to view the goods.`
                )}
              </p>
              <p className="body-small italic">
                {widont(`Don’t worry, your information is safe with us.`)}
              </p>
            </div>
          </div>
        </div>
        <div className="columns is-mobile is-centered is-multiline">
          <div className="column is-7-tablet is-6-desktop is-5-widescreen">
            <form action="#" method="post" onSubmit={handleSubmit}>
              <div className={styles.miniForm}>
                <div className="mb-4">
                  <InputText
                    label="Access code"
                    name="private-auth"
                    id="private-auth"
                    placeholder=""
                    required={true}
                    block={true}
                    onChange={value => {
                      dispatch({ type: "input", fieldName: "password", value })
                    }}
                    htmlAttributes={{
                      autoComplete: "off",
                      autoCorrect: "off",
                      autoCapitalize: "off",
                      spellCheck: "false",
                    }}
                    value={state.fields.password}
                    type="text"
                  />
                </div>
                <div className="mb-6">
                  <InputText
                    label="Your email address"
                    name="private-auth-email"
                    id="private-auth-email"
                    placeholder=""
                    required={true}
                    block={true}
                    onChange={value => {
                      dispatch({ type: "input", fieldName: "email", value })
                    }}
                    value={state.fields.email}
                    type="email"
                  />
                </div>
                <div className="mb-2">
                  <button
                    className="button button--small"
                    type="submit"
                    disabled={state.submitting}
                  >
                    Submit
                  </button>
                </div>
                <div>
                  {state.error && (
                    <div className="error">Incorrect password.</div>
                  )}
                  {state.success && <div className="success">Success!</div>}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
